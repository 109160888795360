<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List User
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <a v-on:click="toggleModal('agency_link')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                Link Perpanjangan User Agency
                            </a>
                            <a v-on:click="toggleModal('new_user')" class="btn px-15 btn-success text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Tambah User
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="lisensiQuota == -1" class="alert alert-success" role="alert">
                <div class="alert-content">
                    <p>Batas user agency maksimal anda adalah <b>Unlimited</b> user</p>
                </div>
            </div>
            <div v-else-if="lisensiQuota == 0" class="alert alert-danger" role="alert">
                <div class="alert-content">
                    <p>Batas user agency maksimal anda adalah <b>{{ lisensiQuota }}</b> user</p>
                </div>
            </div>
            <div v-else class="alert alert-primary" role="alert">
                <div v-if="totalRecord > 0" class="alert alert-danger" role="alert">
					<div class="alert-content">
						<p>Anda mempunyai sisa <b>{{ sisaLisensi }}</b> lisensi dari <b>{{ lisensiQuota }}</b> lisensi</p>
					</div>
				</div>
				<div v-else-if="totalRecord == 0" class="alert alert-primary" role="alert">
					<div class="alert-content">
						<p>Batas user agency maksimal anda adalah <b>{{ lisensiQuota }}</b> user</p>
					</div>
				</div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <UserTable @changeJumlah="ChangeJ($event)" />
                    </div>
                </div>
            </div>
            <modal name="new_user" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddUser/>
                </div>
            </modal>
            <modal name="agency_link" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <center>
                        <h2>Edit Link Perpanjangan User agency</h2>
                    </center>
                    <br>
                    <form v-on:submit.prevent="editAgencySetting">
                        <label for="">URL</label>
                        <input v-model="agencySetting.extend_url" type="text" class="form-control" placeholder="https://example.com">
                        <br>
                        <button type="submit" class="btn btn-block btn-success">Simpan</button>
                    </form>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import UserTable from "@/components/tables/UserAgency.vue";
    import AddUser from "@/components/forms/agency/AddUser.vue";

    export default {
        components: {
            UserTable,
            AddUser
        },
        data() {
            return {
                agencySetting: {
                    extend_url: ""
                },
                lisensiQuota: 0,
                totalRecord:0,
				sisaLisensi:0
            }
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.lisensiQuota = this.$store.state.auth.package.lisensi
            this.loadAgencySetting()
        },
        methods: {
            ChangeJ(jml){
				this.totalRecord = jml
				this.sisaLisensi = this.lisensiQuota-jml
				
			},
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async loadAgencySetting(){
                let setting = await this.$store.dispatch('agency_setting')
                this.agencySetting = setting.data.data.setting
            },
            async editAgencySetting(){
                try{
                    let setting = await this.$store.dispatch('agency_setting_edit', this.agencySetting)
                    this.$toast.success(setting.data.message);
                    this.toggleModal("agency_link")
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }

            }
        },
    };
</script>
